export function createBoundingBox(lat, lng, radius) {
    const R = 6371; // radius of the earth in km
    const latRadian = (Math.PI * lat) / 180; // convert latitude to radians
    const lngRadian = (Math.PI * lng) / 180; // convert longitude to radians
    const d = radius / R; // angular distance in radians on a great circle
  
    const minLat = lat - (d * 180) / Math.PI;
    const maxLat = lat + (d * 180) / Math.PI;
    const minLng =
      lng - ((d * 180) / Math.PI) / Math.cos(latRadian); // compensate for the shrinking of the east-west distances at higher latitudes
    const maxLng =
      lng + ((d * 180) / Math.PI) / Math.cos(latRadian); // compensate for the shrinking of the east-west distances at higher latitudes
  
    return {
      boundingBox: {
        sw: { long: minLng.toFixed(14), lat: minLat.toFixed(14) },
        ne: { long: maxLng.toFixed(14), lat: maxLat.toFixed(14) },
      },
    };
  }
  