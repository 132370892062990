import React, { useState, useRef } from 'react';
import { Box, List, ListItem, ListItemText, Typography, Divider, ListItemAvatar, Avatar, useMediaQuery, Grid } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import theme from '../../style/theme';
import { calculateDistance } from './distanceUtils';
import RouteIcon from '@mui/icons-material/ForkRight';
import reparaturbonus from '../../media/images/reparaturbonus.png';

const ListComponent = React.memo(({ data, handleClick, location, mapRef }) => {
  const listRef = useRef(null);
  const itemRefs = {};
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  const mapComponent = mapRef.current;

  const handleItemClick = (item) => {
    if (mapComponent) {
      mapComponent.handleMarkerClick(item);
    }
  };

  let totalItems = 0;

  Object.keys(data).forEach(category => {
    if (data[category]["visible"] === true) {
      totalItems += data[category]["data"].length;
    }
  });


  return (
    //calculate height of list as total height (90vh) minus height of Search component

    <Box sx={{ bgcolor: theme.palette.secondary.main, width: '100%', '&::-webkit-scrollbar': { display: "none" }, marginTop: -0.7 }}>
      <List sx={{ fontFamily: "Syne", fontWeight: "bold", overflow: 'auto', maxHeight: '100%', marginTop: 0 }}>
        {isDesktop && (
          <>
            {totalItems === 0 && (
              <Typography
                sx={{
                  textAlign: 'right',
                  color: theme.palette.primary.main,
                  fontFamily: 'Inter',
                  fontSize: '12px',
                  marginBottom: '2px',
                  marginRight: '10px',
                }}
              >
                Keine Ergebnisse
              </Typography>
            )}
            {totalItems > 0 && (
              <Typography
                sx={{
                  textAlign: 'right',
                  color: theme.palette.primary.main,
                  fontFamily: 'Inter',
                  fontSize: '12px',
                  marginBottom: '2px',
                  marginRight: '10px',
                }}
              >
                {totalItems} Ergebnisse
              </Typography>
            )}
          </>
        )}

        {Object.keys(data).map((categoryName) => {
          if (data[categoryName]["visible"] === true) {
            return data[categoryName]["data"].map((item, index) => {
              let borderColor;
              switch (item.offer_type.id) {
                case '5':
                  borderColor = '#ffc53b';
                  break;
                case '1':
                  borderColor = '#bdd85b';
                  break;
                case '2':
                  borderColor = '#bdd85b';
                  break;
                case '4':
                  borderColor = '#00aea3';
                  break;
                case '3':
                  borderColor = '#ffc53b';
                  break;
                default:
                  borderColor = 'transparent';
                  break;
              }
              return (
                <ListItem key={"list_" + item.id} ref={(ref) => (itemRefs[item.id] = ref)} divider={index !== data.length - 1} sx={{ ':hover': { bgcolor: "#e9e9e9" }, borderRight: `3px solid ${borderColor}`, borderRadius: "2px" }} onClick={() => handleItemClick(item)}>
                  <ListItemAvatar>
                    <Avatar src={item.image_url} />
                  </ListItemAvatar>
                  <Grid container direction="row" justifyContent="space-between" alignItems="flex-start" spacing={1}>
                    <Grid item xs={9}>
                      <ListItemText
                        primary={item.title}
                        secondary={item.short_description}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      {location && location.latitude && item.locations && item.locations[0] && (
                        <ListItemText
                          primary={
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: "flex-end" }}>
                              <RouteIcon sx={{ fontSize: 17 }} />
                              <span> {calculateDistance(location.latitude, location.longitude, parseFloat(item.locations[0].lat), parseFloat(item.locations[0].long))} km</span>
                            </div>
                          }
                          primaryTypographyProps={{ fontSize: '10px', textAlign: 'right' }}
                        />
                      )}
                      {item.categories && item.categories.map((category) => category.title).includes("Reparaturbonus") && (
                        <ListItemText
                          primary={
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: "flex-end" }}>
                              <img src={reparaturbonus} style={{ width: "60px", marginRight: "5px" }} alt={{}} />
                            </div>
                          }
                          primaryTypographyProps={{ fontSize: '10px', textAlign: 'right' }}
                        />
                      )}
                    </Grid>

                  </Grid>
                </ListItem>
              );
            })
          }
        })}
      </List>
    </Box>
  );
});

export default ListComponent;
