import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Search from '../Search';
import { Autocomplete, Card, CardActions, CardContent, Button, Typography, TextField, Stack, Fab, Checkbox, Divider, InputAdornment, List, Box, FormControl, InputLabel, Select, MenuItem, Grid, ListItem, IconButton, OutlinedInput, Chip, Dialog } from '@mui/material';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  NearMe as NearMeIcon,
  Search as SearchIcon,
  RestartAlt as ResetIcon,
  RadioButtonUnchecked as CheckBoxOutlineBlankIcon,
  CheckCircle as CheckBoxIcon,
  SignLanguage as SignLanguageIcon,
  PedalBike as PedalBikeIcon,
  LocationOn as LocationOnIcon,
  GpsFixed as GpsFixedIcon,
} from '@mui/icons-material';

import { TwitterPicker } from 'react-color';

const uncheckedIcon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const defaultColors = { 'primary': '#e66465', 'secondary': '#ff0000', 'third': '#ffc53b' };

const Configurator = () => {
  const [locations, setLocations] = useState([]);
  const [activities, setActivities] = useState([]);
  const [things, setThings] = useState([]);
  const [colors, setColors] = useState({ 'primary': '#e66465', 'secondary': '#ff0000', 'third': '#ffc53b' });
  const [selectedUserLocation, setSelectedUserLocation] = useState();
  const [selectedActivity, setSelectedActivity] = useState([]);
  const [selectedThing, setSelectedThing] = useState([]);
  const [showTypography, setShowTypography] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [query, setQuery] = useState('');
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [radius, setRadius] = useState();
  const [iframe, setIframe] = useState('');

  //reset all autocomplete fields
  const handleReset = () => {
    setSelectedUserLocation([]);
    setSelectedActivity([]);
    setSelectedThing([]);
    setRadius(5);
  };

  const handleRadiusChange = (event) => {
    setRadius(event.target.value);
  };

  useEffect(() => {
    fetchLocations();
    fetchActivities();
    fetchThings();
  }, []);

  const fetchLocations = () => {
    // Make the first POST request to get the key
    axios.post(process.env.REACT_APP_DBNS_API_URL, { "resource": "NLS.CategoryTypeResource", "action": "get", "params": { "id": 5 }, "fields": { "target_type": true, "title": true, "categories": { "title": true } } })
      .then(response => {
        setLocations(response.data.data.categories);
      })
      .catch(error => {
        console.log(error);
      }
      );
  };

  const fetchActivities = () => {
    // Make the first POST request to get the key
    axios.post(process.env.REACT_APP_DBNS_API_URL, { "resource": "NLS.CategoryTypeResource", "action": "get", "params": { "id": 13 }, "fields": { "target_type": true, "title": true, "categories": { "title": true } } })
      .then(response => {
        setActivities(response.data.data.categories);
        window.activities = [];
        response.data.data.categories.map((activity) => {
          window.things.push(activity.title);
        });
      })
      .catch(error => {
        console.log(error);
      }
      );
  };

  const fetchThings = () => {
    // Make the first POST request to get the key
    axios.post(process.env.REACT_APP_DBNS_API_URL, { "resource": "NLS.CategoryTypeResource", "action": "get", "params": { "id": 4 }, "fields": { "target_type": true, "title": true, "categories": { "title": true } } })
      .then(response => {
        setThings(response.data.data.categories);
        window.things = [];
        response.data.data.categories.map((thing) => {
          window.things.push(thing.title);
        });
      })
      .catch(error => {
        console.log(error);
      }
      );
  };

  const handleUserLocationChange = (event, newValue) => {
    setSelectedUserLocation(newValue); // update state with the new value
  };


  const handleActivityChange = (event, newValue) => {
    setSelectedActivity(newValue);
  };

  const handleThingChange = (event, newValue) => {
    setSelectedThing(newValue);
  };

  const handleInputChange = (event, value) => {
    setInputValue(value);
  };

  const handleSearch = async (event) => {
    const url = `https://photon.komoot.io/api/?q=${query}&lang=de&limit=5`;
    try {
      const response = await axios.get(url);
      const results = response.data.features.map((feature) => {
        const key = feature.id || feature.properties.osm_id;
        let label = feature.properties.name;
        if (feature.properties.street && feature.properties.housenumber) {
          label += `,${feature.properties.street} ${feature.properties.housenumber}`;
        }
        if (feature.properties.city) {
          label += `, ${feature.properties.city}`;
        }
        label += `, ${feature.properties.country}`;
        return {
          key,
          label,
          latitude: feature.geometry.coordinates[1],
          longitude: feature.geometry.coordinates[0],
        };
      });
      setLocations(results);
    } catch (error) {
      console.error(error);
    }
  };

  const getIframeCode = () => {
    let iframeCode = `<iframe src="https://www.map.remap-sachsen.de/#/?`;
    //if selectedUserLocation is an object and not empty
    if (typeof selectedUserLocation === 'object' && Object.keys(selectedUserLocation).length > 0) {
      //remove spaces of selectedUserLocation.label
      selectedUserLocation.label = selectedUserLocation.label.replace(/\s/g, '');
      iframeCode += `userLocation=${selectedUserLocation.label}&userLocationLat=${selectedUserLocation.latitude}&userLocationLon=${selectedUserLocation.longitude}&`;
    }
    if (selectedActivity.length > 0) {
      iframeCode += `activity=${selectedActivity.map((activity) => activity.id).join(',')}&`;
    }
    if (selectedThing.length > 0) {
      iframeCode += `thing=${selectedThing.map((thing) => thing.id).join(',')}&`;
    }
    //if radius is not undefined
    if (radius !== undefined) {
      iframeCode += `radius=${radius}&`;
    }
    if (colors['primary'] !== defaultColors['primary']) {
      iframeCode += `primary=${colors['primary']}&`;
    }
    if (colors['secondary'] !== defaultColors['secondary']) {
      iframeCode += `secondary=${colors['secondary']}&`;
    }
    if (colors['third'] !== defaultColors['third']) {
      iframeCode += `third=${colors['third']}&`;
    }
    iframeCode += `width="100%"&height="100%"&allowfullscreen=""></iframe>`;
    setIframe(iframeCode);
    //open dialog with iframe code
    setOpen(true);
  };


  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      bgcolor="#f0f0f0"
    >
      <Box
        width="75vw"
        height="75vh"
        border={1.5}
        borderColor="#333"
        bgcolor="white"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Stack spacing={1} >

          <Typography variant="h4" sx={{ fontWeight: "bold" }}>Der ReMap-Konfigurator</Typography>
          <Box sx={{ overflow: 'hidden', backgroundColor: '#D3D3D3', padding: '10px', borderRadius: "15px", border: "1px solid #000000" }}>
            <Typography variant="h6">1. Schritt: Farben auswählen</Typography>
            <Grid container>
              <Grid item xs={4}>
                <Typography>1. Farbe</Typography>
                <input
                  type="color"
                  value={colors['primary']}
                  onChange={(e) => setColors({ ...colors, primary: e.target.value })}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography>2. Farbe</Typography>
                <input
                  type="color"
                  value={colors['secondary']}
                  onChange={(e) => setColors({ ...colors, primary: e.target.value })}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography>3. Farbe</Typography>
                <input
                  type="color"
                  value={colors['third']}
                  onChange={(e) => setColors({ ...colors, primary: e.target.value })}
                />
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ overflow: 'hidden', backgroundColor: '#ffc53b', padding: '10px', borderRadius: "15px", border: "1px solid #ffb400" }}>
            <Typography variant="h6">2. Schritt: Ort eingeben</Typography>
            <Grid container>
              <Grid item xs={7}>
                <Autocomplete
                  freeSolo
                  id="photon_complete"
                  noOptionsText={'Gib einen Ort ein!'}
                  options={locations}
                  value={selectedUserLocation}

                  onInputChange={(event, newValue) => {
                    handleSearch();
                  }
                  }
                  onChange={handleUserLocationChange}
                  size="small"
                  variant="standard"
                  sx={{
                    ".MuiInputBase-input": {
                      border: "0px solid",
                      height: "18px",
                    },
                    ".MuiInputBase-root": {
                      backgroundColor: "#FFF9EB",
                      borderRadius: "6px",
                      padding: "5px"
                    },
                    ".MuiInputLabel-root": {
                      fontWeight: 'bold',
                      fontFamily: 'Syne',
                      fontSize: "1.1em",
                      color: "#000",
                    },
                    ".MuiChip-root": {
                      height: "24px",
                      borderRadius: "12px",
                      fontSize: "11px",
                      backgroundColor: "#3f51b5",
                      color: "#fff"
                    },
                    ".MuiChip-root .MuiChip-label": {
                      paddingLeft: "8px",
                      paddingRight: "8px"
                    },
                    ".MuiChip-root:not(:first-of-type)": {
                      marginLeft: "4px"
                    }

                  }}
                  getOptionLabel={(options) => options.label}
                  renderInput={(params) => <TextField {...params} sx={{ fontSize: "0.8em", padding: "0px" }} onChange={(event) => setQuery(event.target.value)} variant="standard" size="small" label="WO BIST DU?" InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <>
                        <InputAdornment position="start">
                          <NearMeIcon />
                        </InputAdornment>
                        {params.InputProps.startAdornment}
                      </>
                    )
                  }} />}
                />
              </Grid>
              <Grid item xs={1}>
                <IconButton edge="end" aria-label="delete" sx={{ marginLeft: "1px", marginTop: "10px" }} onClick={() => setSelectedUserLocation({ label: "Mein Standort", key: 1 })}>
                  <GpsFixedIcon />
                </IconButton>
              </Grid>
              <Grid item xs={4}>
                <FormControl variant="standard" size="small" sx={{
                  ".MuiInputLabel-root": {
                    fontFamily: 'Syne',
                    fontWeight: 'bold',
                    fontSize: "0.7em",
                    marginBottom: "-5px",
                  },
                  ".MuiInputBase-input": {
                    backgroundColor: "#FFF9EB",

                  },
                  ".MuiInputBase-root": {
                    backgroundColor: "#FFF9EB",
                    borderRadius: "6px",
                    padding: "3px",
                  },
                  marginTop: "-2px",
                  ml: 1,
                  minWidth: 100,

                }}>
                  <InputLabel>Radius</InputLabel>
                  <Select onChange={handleRadiusChange} value={radius}>
                    <MenuItem value={1}>1 km</MenuItem>
                    <MenuItem value={2}>2 km</MenuItem>
                    <MenuItem value={5}>5 km</MenuItem>
                    <MenuItem value={10}>10 km</MenuItem>
                    <MenuItem value={20}>20 km</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ overflow: 'hidden', backgroundColor: '#bdd85b', padding: '10px', borderRadius: "15px", border: "1px solid #9ab82c" }}>
            <Typography variant="h6">3. Schritt: Dinge als Vorauswahl eingeben</Typography>
            <Autocomplete
              id="things_select"
              multiple
              disablePortal
              disableCloseOnSelect
              options={things}
              value={selectedThing}
              onChange={handleThingChange}
              noOptionsText={'Dieses Ding gibt es leider nicht :('}
              renderOption={(props, things, { selected }) => (
                <List {...props} style={{ fontSize: "0.8em", padding: 0 }} size="small">
                  <Checkbox
                    icon={uncheckedIcon}
                    checkedIcon={checkedIcon}
                    style={{
                      transform: "scale(0.8)"
                    }}
                    checked={selected}
                  />
                  {things.title}
                </List>
              )}
              size="small"
              variant="standard"
              sx={{
                ".MuiInputBase-input": {
                  border: "0px solid",
                  height: "18px",
                },
                ".MuiInputBase-root": {
                  backgroundColor: "#F8FBEE",
                  borderRadius: "6px",
                  padding: "5px"
                },
                ".MuiInputLabel-root": {
                  fontWeight: 'bold',
                  fontFamily: 'Syne',
                  fontSize: "0.9em",
                  color: "#000"
                },
                ".MuiChip-root": {
                  height: "24px",
                  borderRadius: "12px",
                  fontSize: "11px",
                  backgroundColor: "#3f51b5",
                  color: "#fff"
                },
                ".MuiChip-root .MuiChip-label": {
                  paddingLeft: "8px",
                  paddingRight: "8px"
                },
                ".MuiChip-root:not(:first-of-type)": {
                  marginLeft: "4px"
                }

              }}
              getOptionLabel={(things) => things.title}
              renderInput={(params) => <TextField {...params} variant="standard" size="small" label="WAS HAST ODER SUCHST DU?" InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <>
                    <InputAdornment position="start">
                      <PedalBikeIcon />
                    </InputAdornment>
                    {params.InputProps.startAdornment}
                  </>
                )
              }} />}
            />
          </Box>
          <Box sx={{ overflow: 'hidden', backgroundColor: '#00aea3', padding: '10px', borderRadius: "15px", border: "1px solid #00948b" }}>
            <Typography variant="h6">4. Schritt: Tätigkeiten als Vorauswahl eingeben</Typography>
            <Autocomplete
              id="activities_select"
              multiple
              disablePortal
              disableCloseOnSelect
              options={activities}
              value={selectedActivity}
              noOptionsText={'Diese Aktivität gibt es leider nicht :('}
              onChange={handleActivityChange}
              renderOption={(props, activities, { selected }) => (
                <List {...props} style={{ fontSize: "0.8em", padding: 0 }} size="small">
                  <Checkbox
                    icon={uncheckedIcon}
                    checkedIcon={checkedIcon}
                    style={{
                      transform: "scale(0.8)"
                    }}
                    checked={selected}
                  />
                  {activities.title}
                </List>
              )}
              size="small"
              variant="standard"
              sx={{
                ".MuiInputBase-input": {
                  border: "0px solid",
                  height: "18px",
                },
                ".MuiInputBase-root": {
                  backgroundColor: "#E5F6F5",
                  borderRadius: "6px",
                  padding: "5px"
                },
                ".MuiInputLabel-root": {
                  fontWeight: 'bold',
                  fontFamily: 'Syne',
                  fontSize: "0.9em",
                  color: "#000"
                },
                ".MuiChip-root": {
                  height: "24px",
                  borderRadius: "12px",
                  fontSize: "11px",
                  backgroundColor: "#3f51b5",
                  color: "#fff"
                },
                ".MuiChip-root .MuiChip-label": {
                  paddingLeft: "8px",
                  paddingRight: "8px"
                },
                ".MuiChip-root:not(:first-of-type)": {
                  marginLeft: "4px"
                },
              }}
              getOptionLabel={(activities) => activities.title}
              renderInput={(params) => <TextField {...params} variant="standard" size="small" label="WAS WILLST DU DAMIT MACHEN?" InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <>
                    <InputAdornment position="start">
                      <SignLanguageIcon />
                    </InputAdornment>
                    {params.InputProps.startAdornment}
                  </>
                )
              }} />}
            />
          </Box>
          <Stack direction="row" spacing={2} sx={{ paddingTop: "15px", paddingBottom: "5px", paddingRight: "5px", justifyContent: "right" }}>
            <Fab variant="extended" size="medium" sx={{ backgroundColor: "#9e9e9e", fontFamily: 'Syne', fontWeight: 'bold', fontSize: '12px' }} onClick={handleReset}>
              <ResetIcon sx={{ mr: 1 }} />
              Von vorn
            </Fab>
            <Fab variant="extended" size="medium" color="primary" aria-label="add" onClick={getIframeCode} sx={{ fontFamily: 'Syne', fontWeight: 'bold', fontSize: '12px' }}>
              <SearchIcon sx={{ mr: 1 }} />
              Code erzeugen
            </Fab>
          </Stack>
        </Stack>
        <Dialog open={open} onClose={() => setOpen(false)} sx={{ width: '100%', height: '100%' }}>
          <Box p={2}>
          <pre>{iframe}</pre>
          <Button onClick={() => {navigator.clipboard.writeText(iframe)}}>Copy to clipboard</Button>
          </Box>
        </Dialog>
      </Box>
    </Box>
  )
}

export default Configurator;