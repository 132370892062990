import { createTheme } from '@mui/material/styles';

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#00948b', // your primary color, magenta b7295f
    },
    secondary: {
      main: '#fff', // your secondary color, light yellow f2f2ea
    },
    third: {
        main: '#bbc0d2', // your third color, blue bbc0d2
    },
    fourth: {
        main: '#d5cfd9', // your fourth color, dark grey d5cfd9
    },
    fifth: {
        main: '#329d98', // your fifth color, yellow 329d98
    },
    sixth: {
        main: '#2c328d', // your sixth color, dark blue 2c328d
    },
    style1: {
      main: '#ffb400',
    },
    style2: {
      main: '#9ab82c',
    },
    style3: {
      main: '#00948b',
    }

  },
});

export default defaultTheme;

/*const theme = createTheme({
  palette: {
    primary: {
      main: '#', // your primary color, magenta
    },
    secondary: {
      main: '#eeead3', // your secondary color, light yellow
    },
    third: {
        main: '#384fbf', // your third color, blue
    },
    fourth: {
        main: '#090203', // your fourth color, dark grey
    },
    fifth: {
        main: '#feed4f', // your fifth color, yellow
    },
    sixth: {
        main: '#2c328d', // your sixth color, dark blue
    },
  },
});*/
