const steps = [
  {
    content: 'Schön, dass du zu uns gefunden hast! Wir möchten dir hier kurz zeigen, wie die ReMap funktioniert.',
    position: "center"
  },
  {
    selector: '.search-container',
    content: 'Hier findest du die Suchleiste. Wähle einfach aus, wo du bist, was du hast und was du damit machen willst. Dann klicke auf "Suchen".', 
  },
  {
    selector: '.results-list',
    content: 'Hier siehst du die Ergebnisse deiner Suche. Du kannst mit dem Mausrad zoomen, um mehr Ergebnisse zu sehen. Wenn du auf einen der Punkte klickst, öffnet sich ein Fenster mit weiteren Informationen.', 
    position: "center"
  },
  {
    selector: '.CardDetailedview',
    content: 'Dies ist die Detailansicht. Hier kannst du sehen, was du in der Einrichtung finden oder machen kannst.',
  },
  {
    selector: '.favoriteButton-Detail',
    content: 'Falls dir die Einrichtung gefällt, kannst du sie mit einem Klick auf den Button zu deinen Favoriten hinzufügen. Du findest sie dann in der Favoritenliste wieder.',
  },
  {
    selector: '.closeButton-Detail',
    content: 'Hier kannst du die Detailansicht schließen.',
  },
  {
    selector: '.mapContainer-wrapper',
    content: 'Hier siehst du die Karte. Du kannst sie mit der Maus bewegen und mit dem Mausrad zoomen.', 
    position: "center"
  },
  {
    selector: '.minimapContainer-wrapper',
    content: 'Dies ist die Minimap, auf der du in grün alle Landkreise in Sachsen siehst, die schon an der ReMap teilnehmen.', 
  },
  {
    selector: '.favoritesList-Button',
    content: 'Mit Klick auf das Herz öffnet sich deine Favoritenliste. Hier findest du alle Einrichtungen, die du zu deinen Favoriten hinzugefügt hast.', 
  },
  {
    content: 'Das war es schon mit unserer kurzen Tour! Alle weiteren Dinge kannst du selbst herausfinden. Viel Spaß beim Stöbern!',
    position: "center"
  },
]

export default steps;