import React, { useState, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Box, Card, CardMedia, CardContent, CardActions, Button, Typography, Grid } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import theme from '../../style/theme';
import 'swiper/css';
import './mobileliststyle.css';
import ShowMoreText from "react-show-more-text";
import useFitText from "use-fit-text";

const getRandomScribbleImage = () => {
  const images = require.context('../../media/images/scribbles', false, /\.(png|svg)$/);
  const imageKeys = images.keys();
  const randomIndex = Math.floor(Math.random() * imageKeys.length);
  return images(imageKeys[randomIndex]);
};

const SmallMobileListComponent = ({ data, handleClick }) => {
  const listRef = useRef(null);
  const { fontSize, ref } = useFitText();

  const handleItemClick = (item, scrollIntoView = false) => {
    handleClick(item);
    if (scrollIntoView && listRef.current) {
      const listItem = listRef.current.querySelector(`[data-item-id="${item.id}"]`);
      if (listItem) {
        listItem.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  };

  return (
    <Box sx={{ width: "100vw", height: "13vh"}}>
      <Swiper
        centeredSlides={true}
        centeredSlidesBounds={true}
        slidesPerView={2.1}
        spaceBetween={5}
        pagination={{
          clickable: true,
        }}
      >
        {data.map((item, index) => {
          let borderColor;
          switch (item.offer_type.display_name) {
            case 'Projekt':
              borderColor = 'green';
              break;
            case 'Veranstaltung':
              borderColor = 'yellow';
              break;
            case 'Initiative':
              borderColor = 'red';
              break;
            default:
              borderColor = 'transparent';
              break;
          }
          return (
            <SwiperSlide key={uuidv4()} sx={{ height: "100%", alignSelf: "stretch"}}>
              <Card sx={{ ':hover': { bgcolor: "#ededed" }, borderRight: `2px solid ${borderColor}`, borderRadius: "2px", height: "100%" }} onClick={() => handleItemClick(item)}>
              <Grid direction="row" container>
                <Grid item xs={2}>
              <CardMedia
                  component="img"
                  alt="project image"
                  width="40"
                  image={item.image_url || getRandomScribbleImage()}
                />
              </Grid>
              <Grid item xs>
              <CardContent sx={{ overflowY: "auto", height: 40 }}>
                  <Typography gutterBottom variant="h6" sx={{ fontSize: 15, lineHeight: 1.1, overflowWrap: "anywhere", marginTop: "-8px"}} className="smallmobile_title">
                  {item.title}
                  </Typography>
                  {item.locations[0] && (item.locations[0].street || item.locations[0].zip || item.locations[0].city) &&
                  <Typography variant="body2" color="text.secondary" sx={{ fontSize: 12, lineHeight: 1.1, overflowWrap: "anywhere" }}>
                  {item.locations[0].street ? `${item.locations[0].street}, ` : ''}
                                {item.locations[0].zip ? `${item.locations[0].zip} ` : ''}
                                {item.locations[0].city ? `${item.locations[0].city}` : ''}
                    </Typography>
                    }
                </CardContent>
                </Grid>
                </Grid>
              </Card>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Box>
  );
};

export default SmallMobileListComponent;