import React from "react";
import ReactDOM from "react-dom/client";
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import Site1 from './components/Site1';
import Configurator from './components/Configurator';
import './index.css';
import { TourProvider } from '@reactour/tour';
import steps from './components/Intro/steps';
import { ErrorBoundary } from "react-error-boundary";
import reportWebVitals from "./reportWebVitals";

function ErrorFallBack({ error, resetErrorBoundary }) {
  return (
    <div role="alert">
      <p>Entschuldige, hier ist etwas schief gegangen. Vermutlich wird es daran gelegen haben:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Lade die Seite neu</button>
    </div>
  )
}

const App = () => {

  const [errorKey, setErrorKey] = React.useState(null)

  const redirectToSite = (site) => {
    window.location.href = `/${site}`;
  };

  return (
    <ErrorBoundary
    FallbackComponent={ErrorFallBack}
    onReset={() => setErrorKey(null)}
    resetKeys={errorKey}
  >
    <Router>
      <div>
        <Routes>
          <Route path="/site1" element={<TourProvider steps={steps}><Site1 /></TourProvider>} />
          <Route path="/" element={<TourProvider steps={steps}><Site1 /></TourProvider>} />
          <Route path="/configurator" element={<Configurator />} />
        </Routes>
      </div>
    </Router>
    </ErrorBoundary>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();

/*            <SpeedDial
        ariaLabel="SpeedDial basic example"
        sx={{ position: 'absolute', top: 20, right: 16 }}
        icon={<SpeedDialIcon />}
        direction={'down'}
        FabProps={{
          sx: {
            bgcolor: 'primary.main',
            '&:hover': {
              bgcolor: 'secondary.main',
            }
          }
        }}
      >
                  <SpeedDialAction
            key={'variant1'}
            icon={<Filter1Icon />}
            tooltipTitle={'Variante 1'}
            onClick={() => redirectToSite('site1')}
          />
                    <SpeedDialAction
            key={'variant2'}
            icon={<Filter2Icon />}
            tooltipTitle={'Variante 2'}
            onClick={() => redirectToSite('site2')}
          />
              </SpeedDial>*/