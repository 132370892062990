import * as React from 'react';
import { Card, CardContent, CardMedia, Typography, Button, CardActionArea, CardActions, Grid, Divider, IconButton, Box, CardHeader } from '@mui/material';
import ShowMoreText from "react-show-more-text";
import './mobile_style.css';
import {
    Close as CloseIcon
} from '@mui/icons-material';

const getRandomScribbleImage = () => {
    const images = require.context('../../media/images/scribbles', false, /\.(png|svg)$/);
    const imageKeys = images.keys();
    const randomIndex = Math.floor(Math.random() * imageKeys.length);
    return images(imageKeys[randomIndex]);
};

const SmallMobileView = ({ selectedItem, onClose, clickDetailedView }) => {

    const [openFavAdd, setOpenFavAdd] = React.useState(false);

    const setFavorite = (item) => {
        setOpenFavAdd(true);
        // Retrieve existing favorites data from localStorage
        const favorites = JSON.parse(localStorage.getItem('favorites')) || [];
        // Append new favorite to existing favorites
        favorites.push(item);
        // Set updated favorites data back to localStorage
        localStorage.setItem('favorites', JSON.stringify(favorites));

    }

    return (
        <Card sx={{ height: "100%", borderRadius: "20px" }}>
            <Grid direction="row" container>
                <Grid item xs={3}>
                    <CardMedia
                        sx={{ height: "100%" }}
                        image={selectedItem.image_url || getRandomScribbleImage()}
                        title={selectedItem.title}
                    />
                </Grid>
                <Grid item xs>
                    <CardHeader
                        titleTypographyProps={{ fontFamily: "Syne", fontWeight: "bold", fontSize: "12px", textTransform: "uppercase", p: 0, m: 0 }}
                        subheaderTypographyProps={{ fontFamily: "Syne", fontWeight: "bold", fontSize: "12px" }}
                        action={
                            <IconButton aria-label="settings" onClick={onClose}>
                                <CloseIcon />
                            </IconButton>
                        }
                        title={selectedItem.title}
                        sx={{ mb:-4, mt: -1 }}
                    />
                    <CardContent sx={{ m: 0, }}>
                                <Typography variant="body1" sx={{ fontFamily: "Inter", fontSize: "11px", paddingTop: "5px" }}>
                                {selectedItem.locations[0].street ? `${selectedItem.locations[0].street}, ` : ''}
                                    {selectedItem.locations[0].zip ? `${selectedItem.locations[0].zip} ` : ''}
                                    {selectedItem.locations[0].city ? `${selectedItem.locations[0].city}` : ''}
                                </Typography>
                    </CardContent>
                    <CardActions sx={{ml: 1, mt: -1, pt: 0}}>
                        <Button variant="outlined" sx={{ fontFamily: "Inter", fontSize: "11px", textTransform: "none", fontWeight: "bold", width: "100%" }} onClick={clickDetailedView}>Details</Button>
                        </CardActions>
                </Grid>
            </Grid>
        </Card>
    );
}

export default SmallMobileView;