import React, { useState, useEffect, useCallback } from 'react';
import {
    Box,
    Card,
    CardContent,
    CardMedia,
    CardActions,
    Chip,
    IconButton,
    Typography,
    Grid,
    Divider,
    Stack,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Alert,
    Snackbar,
    Slide,
    Link,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    TextField
} from '@mui/material';
import {
    Close as CloseIcon, Share as ShareIcon, Favorite as FavoriteIcon, OpenInNew as OpenInNewIcon, LocationOn as LocationOnIcon, AssistantDirection as AssistantDirectionIcon, AlternateEmail as AlternateEmailIcon, Call as CallIcon,
    PedalBike as PedalBikeIcon, HelpOutline as HelpOutlineIcon, AccessTimeFilled as AccessTimeFilledIcon
} from '@mui/icons-material';
import ShowMoreText from "react-show-more-text";
import axios from "axios";
import './style.css';
import kellmap from '../../media/images/KELLmap_offers.png';
import reparaturbonus from '../../media/images/reparaturbonus.png';


const DetailedView = ({ selectedItem, onClose }) => {

    const [openFavAdd, setOpenFavAdd] = useState(false);
    const [favAddText, setFavAddText] = useState('');
    const [detailedData, setDetailedData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [formOpen, setFormOpen] = useState(false);
    const [suggestion, setSuggestion] = useState({ email: '', suggestion: '' });
    const [successOpen, setSuccessOpen] = useState(false);
    const [errorOpen, setErrorOpen] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const getSingleRandomScribbleImage = () => {
        const images = require.context('../../media/images/scribbles', false, /\.(png|svg)$/);
        const imageKeys = images.keys();
        const randomIndex = Math.floor(Math.random() * imageKeys.length);
        return images(imageKeys[randomIndex]);
    };

    const randomScribbleImage = getSingleRandomScribbleImage();

    //check if item is already in favorites on render and color icon accordingly

    useEffect(() => {
        const favorites = JSON.parse(localStorage.getItem('favorites')) || [];
        const alreadyInFavorites = favorites.some(favorite => favorite.id === selectedItem.id);
        if (alreadyInFavorites) {
            document.getElementsByClassName("favoriteButton-Detail")[0].style.color = "#ff0000";
        } else {
            document.getElementsByClassName("favoriteButton-Detail")[0].style.color = "#000000";
        }
    }, [selectedItem]);


    const setFavorite = (item) => {
        // Retrieve existing favorites data from localStorage
        const favorites = JSON.parse(localStorage.getItem('favorites')) || [];
        //check if item is already in favorites
        const alreadyInFavorites = favorites.some(favorite => favorite.id === item.id);
        if (!alreadyInFavorites) {
            setFavAddText("Favorit hinzugefügt!");
            setOpenFavAdd(true);
            favorites.push(item);  // Append new favorite to existing favorites
            localStorage.setItem('favorites', JSON.stringify(favorites)); // Set updated favorites data back to localStorage
            document.getElementsByClassName("favoriteButton-Detail")[0].style.color = "#ff0000";
        } else {
            //delete item from favorites
            setFavAddText("Dein Favorit wurde entfernt!");
            setOpenFavAdd(true);
            const newFavorites = favorites.filter(favorite => favorite.id !== item.id);
            localStorage.setItem('favorites', JSON.stringify(newFavorites)); // Set updated favorites data back to localStorage
            document.getElementsByClassName("favoriteButton-Detail")[0].style.color = "#000000";
        }
    }

    const fetchDetailedData = (selectedItem) => {

        // construct the request body based on the selected filters
        const requestBody = {
            "resource": "NLS.OfferResource",
            "action": "get",
            "params": {
                "id": selectedItem
            },
            "fields": {
                "title": true,
                "short_description": true,
                "description": true,
                "property_list": true,
                "costs": true,
                "requirements": true,
                "curriculum": true,
                "free": true,
                "child_friendly": true,
                "accessible": true,
                "climate_protection": true,
                "event_online": true,
                "event_canceled": true,
                "target_group": true,
                "info_url": true,
                "video_url": true,
                "updated_at": true,
                "image_url": true,
                "offer_type": {
                    "display_name": true,
                    "key": true,
                    "used_attributes": true
                },
                "locations": {
                    "lat": true,
                    "long": true,
                    "title": true,
                    "street": true,
                    "city": true,
                    "zip": true
                }
            }
        };

        // Make the POST request to the external API
        axios.post(process.env.REACT_APP_DBNS_API_URL, requestBody)
            .then(response => {
                const data_arr = response.data.data;
                const newData = Array.isArray(data_arr) ? data_arr : [data_arr];
                setDetailedData(newData);
                setIsLoading(false);
            })
            .catch(error => {
                setError(error);
                setIsLoading(false);
                console.log(error);
            });
    };

    //send form to strapi with endpoint /suggestion
    const handleFormSend = () => {
        if (!validateEmail(suggestion.email)) {
            setEmailError(true);
            return;
        }
        //create postBody
        const postBody = {
            "data": {
                "userEmail": suggestion.email,
                "offerSuggestion": suggestion.suggestion,
                "offerID": selectedItem.id,
                "offerName": selectedItem.title,
                "suggestionStatus": false
            }
        };
        sendSuggestionToStrapi(postBody)
            .then(() => {
                setSuccessOpen(true); // Display success alert
                setTimeout(() => {
                    setSuccessOpen(false); // Close success alert after 3 seconds
                    onClose(); // Close the dialog
                }, 3000);
            })
            .catch((error) => {
                console.error('Error sending suggestion:', error);
                setErrorOpen(true); // Display error alert
                setTimeout(() => {
                    setErrorOpen(false); // Close success alert after 3 seconds
                    onClose(); // Close the dialog
                }, 3000);
            });
    };

    const sendSuggestionToStrapi = (suggestionData) => {
        const strapi_endpoint = process.env.REACT_APP_STRAPI_API_URL + "/remap-offer-suggestions";
        const strapi_token = process.env.REACT_APP_STRAPI_API_TOKEN;

        return axios.post(strapi_endpoint, suggestionData, {
            headers: {
                Authorization: `Bearer ${strapi_token}`,
                'Content-Type': 'application/json',
            },
        });
    };

    const handleSuccessClose = () => {
        setSuccessOpen(false);
    };

    const handleErrorClose = () => {
        setErrorOpen(false);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenFavAdd(false);
    };

    const validateEmail = (email) => {
        // Use a simple email format validation regex or library of your choice
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    //handleFormOpen with useCallback

    const handleFormOpen = useCallback(() => {
        if (formOpen) {
            setFormOpen(false);
        }
        else {
            setFormOpen(true);
        }
    }, [formOpen]);

    let borderColor;
    switch (selectedItem.offer_type.id) {
        case '5':
            borderColor = '#ffc53b';
            break;
        case '1':
            borderColor = '#bdd85b';
            break;
        case '2':
            borderColor = '#bdd85b';
            break;
        case '4':
            borderColor = '#00aea3';
            break;
        case '3':
            borderColor = '#ffc53b';
            break;
        default:
            borderColor = 'transparent';
            break;
    }


    return (
        <Card sx={{ overflowY: "scroll", maxHeight: '88vh', padding: 0, margin: 0, '&::-webkit-scrollbar': { display: "none" }, fontFamily: 'Inter', fontWeight: 'bold' }} className="CardDetailedview" >
            <Stack direction="row" spacing={2} justifyContent="flex-end" alignItems="flex-start" sx={{ mb: -5 }}>
                <IconButton onClick={onClose} sx={{ backgroundColor: "#fff", m: 0.5, p: 0.5 }} className="closeButton-Detail">
                    <CloseIcon />
                </IconButton>
            </Stack>
            <CardMedia
                sx={{
                    width: '100%',
                    paddingTop: '60%'
                }}
                image={selectedItem.image_url || kellmap}
                title={selectedItem.title}
            />
            <CardContent sx={{ boxShadow: "0 -5px 5px -5px #333", borderRadius: "12px", borderTop: `3px solid ${borderColor}` }}>
                <Typography variant="h5" sx={{ fontFamily: "Syne", fontWeight: "bold", fontSize: "22px" }}>{selectedItem.title}</Typography>
                <Typography variant="body1" sx={{ fontFamily: "Syne", fontWeight: "bold", fontSize: "14px", textTransform: "uppercase", color: `${borderColor}` }}>{selectedItem.offer_type.display_name}</Typography>
                <Divider sx={{ m: 0.5 }} />
                <Grid container direction="row" justifyContent="flex-start">
                    <Grid item>
                        <IconButton aria-label="add to favorites" size="small" onClick={() => { setFavorite(selectedItem) }} className="favoriteButton-Detail">
                            <FavoriteIcon />
                        </IconButton>
                        <Snackbar open={openFavAdd} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: "top", horizontal: "right" }} TransitionComponent={Slide}>
                            <Alert onClose={handleClose} severity="info" sx={{ width: '100%' }}>
                                {favAddText}
                            </Alert>
                        </Snackbar>
                    </Grid>
                    <Grid item>
                        <IconButton aria-label="share" size="small">
                            <ShareIcon />
                        </IconButton>
                    </Grid>
                    {selectedItem.locations[0] &&
                        <Grid item>
                            <IconButton aria-label="direction" onClick={() => window.open(`https://www.google.com/maps/search/?api=1&query=${selectedItem.locations[0].lat},${selectedItem.locations[0].long}`)} size="small">
                                <AssistantDirectionIcon />
                            </IconButton>
                        </Grid>
                    }
                </Grid>
                <Grid container spacing={2} alignItems="center" justifyContent="space-between" sx={{ p: 0, m: 0 }}>
                    <Stack direction="row" spacing={0.5} useFlexGap flexWrap="wrap">
                        {selectedItem.categories &&
                            selectedItem.categories.map((category, index) => (
                                (window.things.includes(category.title) || window.activities.includes(category.title)) ? (
                                    <Chip
                                        key={index}
                                        label={category.title}
                                        size="small"
                                        sx={{
                                            backgroundColor: category.title[0].toUpperCase() === category.title[0] ? '#ffc53b' : '#00aea3',
                                        }}
                                    />
                                ) : null
                            ))
                        }
                        {selectedItem.categories && selectedItem.categories.map((category) => category.title).includes("Reparaturbonus") && (
                            <img src={reparaturbonus} style={{ width: "60px", marginRight: "5px" }} />
                        )}
                    </Stack>
                </Grid>
                <Divider sx={{ m: 0.5 }} />

                <List component="nav" sx={{ p: -1, mb: -1, mt: -1 }}>
                    {selectedItem.times && selectedItem.times.length > 0 &&
                        selectedItem.times
                            .filter(time => {
                                const now = new Date();
                                const oneMonthLater = new Date();
                                oneMonthLater.setMonth(now.getMonth() + 12);
                                return new Date(time.start) <= oneMonthLater && new Date(time.start) >= now;
                            })
                            .map((time, index) => (
                                <ListItem key={index} sx={{ ':hover': { bgcolor: "#ededed" }, mt: -0.5 }} disablePadding>
                                    {index === 0 && (
                                        <ListItemIcon aria-label="open link" size="small">
                                            <AccessTimeFilledIcon />
                                        </ListItemIcon>
                                    )}
                                    {index !== 0 && (
                                        <ListItemIcon aria-label="open link" size="small">
                                        </ListItemIcon>
                                    )}
                                    <ListItemText sx={{ fontWeight: "bold" }}>
                                        {new Date(time.start).toLocaleString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' })} - {new Date(time.end).toLocaleString('de-DE', { hour: '2-digit', minute: '2-digit' })} Uhr
                                    </ListItemText>
                                </ListItem>
                            ))
                    }
                    {selectedItem.locations[0] && (selectedItem.locations[0].street || selectedItem.locations[0].zip || selectedItem.locations[0].city) &&
                        <ListItem sx={{ ':hover': { bgcolor: "#ededed" }, mt: -0.5 }} disablePadding>
                            <ListItemIcon aria-label="open link" size="small">
                                <LocationOnIcon />
                            </ListItemIcon>
                            <ListItemText sx={{ fontFamily: "Inter", fontSize: "11px" }}>
                                {selectedItem.locations[0].street ? `${selectedItem.locations[0].street}, ` : ''}
                                {selectedItem.locations[0].zip ? `${selectedItem.locations[0].zip} ` : ''}
                                {selectedItem.locations[0].city ? `${selectedItem.locations[0].city}` : ''}
                            </ListItemText>
                        </ListItem>
                    }
                    {selectedItem.info_url &&
                        <ListItem sx={{ ':hover': { bgcolor: "#ededed" }, mt: -0.2 }} onClick={() => window.open(`${selectedItem.info_url}`)} disablePadding>
                            <ListItemIcon aria-label="open link" size="small">
                                <OpenInNewIcon />
                            </ListItemIcon>
                            <ListItemText sx={{ fontFamily: "Inter", fontSize: "11px" }}>
                                {selectedItem.info_url}
                            </ListItemText>
                        </ListItem>
                    }
                    {selectedItem.contacts[0] && selectedItem.contacts[0].contact_persons[0] && selectedItem.contacts[0].contact_persons[0].phone &&
                        <ListItem sx={{ ':hover': { bgcolor: "#ededed" }, mt: -0.2 }} onClick={() => window.open(`tel:${selectedItem.contacts[0].contact_persons[0].phone}`)} disablePadding>
                            <ListItemIcon aria-label="open link" size="small">
                                <CallIcon />
                            </ListItemIcon>
                            <ListItemText sx={{ fontFamily: "Inter", fontSize: "11px" }}>
                                {selectedItem.contacts[0].contact_persons[0].phone}
                            </ListItemText>
                        </ListItem>
                    }
                    {selectedItem.contacts[0] && selectedItem.contacts[0].contact_persons[0] && selectedItem.contacts[0].contact_persons[0].email &&
                        <ListItem sx={{ ':hover': { bgcolor: "#ededed" }, mt: -0.2 }} onClick={() => window.open(`mailto:${selectedItem.contacts[0].contact_persons[0].email}`)} disablePadding>
                            <ListItemIcon aria-label="open link" size="small">
                                <AlternateEmailIcon />
                            </ListItemIcon>
                            <ListItemText sx={{ fontFamily: "Inter", fontSize: "11px" }}>
                                {selectedItem.contacts[0].contact_persons[0].email}
                            </ListItemText>
                        </ListItem>
                    }
                </List>
                <Divider sx={{ m: 0.5 }} />
                <Typography sx={{ fontWeight: 'bold' }}>Beschreibung</Typography>
                <ShowMoreText
                    /* Default options */
                    lines={5}
                    more="Mehr anzeigen"
                    less="Weniger anzeigen"
                    expanded={false}
                    truncatedEndingComponent={"... "}
                    className="showmoretext-detailedview"
                >
                    <Typography variant="body1" sx={{ fontFamily: "Inter", fontSize: "14px" }}>{selectedItem.description}</Typography>
                </ShowMoreText>
                {selectedItem.property_list &&
                    <>
                        <Divider sx={{ m: 0.5 }} />
                        <Typography sx={{ fontWeight: 'bold' }}>Weitere Infos</Typography>
                        <Typography style={{ whiteSpace: 'pre-line', fontFamily: "Inter", fontSize: "14px" }}>{selectedItem.property_list}</Typography>
                    </>
                }
                <Divider sx={{ m: 0.5 }} />
                <Stack direction="row" alignItems="center" gap={0.5} sx={{ mt: 1, mb: -1 }}>
                    <HelpOutlineIcon sx={{ fontSize: 12 }} />
                    <Link href="#" color="inherit" sx={{ fontSize: 12 }} underline="none" onClick={handleFormOpen}>
                        Eintrag melden/ändern
                    </Link>
                    <Dialog open={formOpen} onClose={() => setFormOpen(false)}>
                        <DialogTitle>{selectedItem.title}: Eintrag melden/ändern</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Hier kannst du uns Änderungsvorschläge oder Fehler melden. Gib einfach deine vorgeschlagenen Änderungen ein und wir werden diese prüfen. Gern kannst du auch deine Mail-Adresse für eventuelle Rückfragen von unserer Seite angeben. Vielen Dank für deine Mithilfe!
                            </DialogContentText>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="form_email"
                                label="Deine E-Mail-Adresse"
                                type="email"
                                fullWidth
                                variant="standard"
                                value={suggestion.email}
                                onChange={(e) => {
                                    setSuggestion({ ...suggestion, email: e.target.value });
                                    setEmailError(false); // Clear email error when typing
                                }}
                                error={emailError}
                                helperText={emailError ? 'Bitte gib deine korrekte Mail-Adresse ein.' : ''}
                            />
                            <TextField
                                autoFocus
                                margin="dense"
                                id="form_changes"
                                label="Deine Änderungsvorschläge/Anmerkungen*"
                                multiline
                                type="textarea"
                                rows={4}
                                fullWidth
                                variant="standard"
                                onChange={(e) => setSuggestion({ ...suggestion, suggestion: e.target.value })}
                            />

                            {successOpen &&
                                <Alert severity="success" onClose={handleSuccessClose}>
                                    Deine Nachricht wurde erfolgreich versendet!
                                </Alert>
                            }
                            {errorOpen &&
                                <Alert severity="error" onClose={handleErrorClose}>
                                    Beim Versenden deiner Nachricht ist ein Fehler aufgetreten. Bitte versuche es später erneut.
                                </Alert>
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setFormOpen(false)}>Schließen</Button>
                            <Button onClick={handleFormSend}>Absenden</Button>
                        </DialogActions>
                    </Dialog>
                </Stack>
            </CardContent>
        </Card >
    );
};

export default DetailedView;
