import React, { useState, useRef, useEffect } from 'react';
import { Box, Grid, List, ListItem, ListItemText, Typography, Divider, ListItemAvatar, Avatar, ListItemButton, IconButton, Paper, Stack, useMediaQuery } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import theme from '../../style/theme';
import {
  Favorite as FavoriteIcon,
  Remove as RemoveIcon,
} from '@mui/icons-material';
import kellmap from '../../media/images/KELLmap_offers.png';

const getRandomScribbleImage = () => {
  const images = require.context('../../media/images/scribbles', false, /\.(png|svg)$/);
  const imageKeys = images.keys();
  const randomIndex = Math.floor(Math.random() * imageKeys.length);
  return images(imageKeys[randomIndex]);
};

const ListComponent = ({ handleClick, mapRef }) => {
  const listRef = useRef(null);
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const [favorites, setFavorites] = useState([]);

  const mapComponent = mapRef.current;
  const handleItemClick = (item, scrollIntoView = false) => {

    if (mapComponent) {
      mapComponent.handleMarkerClick(item);
    }

    if (scrollIntoView && listRef.current) {
      const listItem = listRef.current.querySelector(`[favorites-item-id="${item.id}"]`);
      if (listItem) {
        listItem.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  };

  useEffect(() => {
    // Load favorites from localStorage on component mount
    const storedFavorites = localStorage.getItem('favorites') ? JSON.parse(localStorage.getItem('favorites')) : [];
    setFavorites(storedFavorites);
  }, []);

  const trashFavorite = (id) => {
    //remove favorite from list with id
    const updatedFavorites = favorites.filter((item) => item.id !== id);
    
    console.log(updatedFavorites);
    localStorage.setItem('favorites', JSON.stringify(updatedFavorites));
    setFavorites(updatedFavorites);
  }


  return (
    //calculate height of list as total height (90vh) minus height of Search component

    <Box sx={{ bgcolor: theme.palette.secondary.main, width: '100%', '&::-webkit-scrollbar': { display: "none" } }}>
      {isDesktop && (
        <>
          <Paper sx={{ p: 2, backgroundColor: "#ededed" }}>
            <Stack direction="row" alignItems="center" gap={1}>
              <FavoriteIcon sx={{ color: "red" }} />
              <Typography variant="h7" sx={{ fontFamily: "Syne", fontWeight: "bold" }}>DEINE FAVORITEN</Typography>
            </Stack>
          </Paper>
        </>
      )}
      <List sx={{ fontFamily: "Syne", fontWeight: "bold", overflow: 'auto', maxHeight: '100%' }}>
        {isDesktop && (
          <>
            {favorites.length === 0 && (
              <Typography
                sx={{
                  textAlign: 'right',
                  color: theme.palette.primary.main,
                  fontFamily: 'Inter',
                  fontSize: '12px',
                  marginBottom: '2px',
                  marginRight: '10px',
                }}
              >
                Keine Ergebnisse
              </Typography>
            )}
            {favorites.length > 0 && (
              <Typography
                sx={{
                  textAlign: 'right',
                  color: theme.palette.primary.main,
                  fontFamily: 'Inter',
                  fontSize: '12px',
                  marginBottom: '2px',
                  marginRight: '10px',
                }}
              >
                {favorites.length} Ergebnisse
              </Typography>
            )}
          </>
        )}

        {favorites.map((item, index) => {
          let borderColor;
          switch (item.offer_type.display_name) {
            case 'Projekt':
              borderColor = 'green';
              break;
            case 'Veranstaltung':
              borderColor = 'yellow';
              break;
            case 'Initiative':
              borderColor = 'red';
              break;
            default:
              borderColor = 'transparent';
              break;
          }
          return (
            <ListItem
              key={item.id} // Use the item's ID as the key
              divider={index !== favorites.length - 1}
              sx={{
                ':hover': { bgcolor: '#e9e9e9' },
                borderRight: `2px solid ${borderColor}`,
                borderRadius: '2px',
              }}
              onClick={() => handleItemClick(item)}
            >
              <Grid container alignItems="center">
                <Grid item xs={9}>
                  <Grid container alignItems="center">
                    <ListItemAvatar>
                      <Avatar src={item.image_url || kellmap} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={item.title}
                      secondary={item.short_description}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  <Grid container justifyContent="flex-end">
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={(event) => {
                        event.stopPropagation(); // Prevent click event from bubbling up to ListItem
                        trashFavorite(item.id);
                      }}
                    >
                      <RemoveIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

export default ListComponent;
