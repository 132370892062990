import React, { useContext, useEffect, useRef, useState, useCallback, useMemo, forwardRef, useImperativeHandle } from 'react';
import { MapContainer, TileLayer, Marker, Popup, ZoomControl, GeoJSON, useMapEvents, useMap, Tooltip, useMapEvent, Rectangle } from 'react-leaflet';
import { useEventHandlers } from '@react-leaflet/core'
import Control from 'react-leaflet-custom-control';
import L from 'leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import AppContext from "../../context/AppContext";
import MapPopup from '../MapPopup';
import marker_icon from '../../media/images/marker_remap.png';
import marker_icon_svg from '../../media/images/marker_remap.svg';
import marker_repair_icon from '../../media/images/marker_repair.png';
import marker_repair_icon_svg from '../../media/images/marker_repair.svg';
import selected_marker_icon from '../../media/images/selected_marker_remap.png';
import selected_marker_icon_svg from '../../media/images/selected_marker_remap.svg';
import marker_iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { v4 as uuidv4 } from 'uuid';
import { CircularProgress, Stack, IconButton, useMediaQuery, Avatar, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import saxonyBorder from '../../saxonyBorder.geo.json';
import saxonyRegionsBorder from '../../saxonyRegionsBorder.geo.json';
import saxonyRegionsBorderincluded from '../../saxonyRegionsBorderincluded.geo.json';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  NearMe as NearMeIcon,
  Search as SearchIcon,
  ShareLocation as ShareLocationIcon,
} from '@mui/icons-material';
import './style.css';
import defaultTheme from '../../style/theme';
import youarehere from '../../media/images/youarehere_german.png';

const DefaultIcon = new L.Icon({
  iconUrl: marker_icon,
  iconRetinaUrl: marker_icon_svg,
  iconAnchor: [10, 35],
  shadowUrl: marker_iconShadow,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: [26, 40],
});

const RepairIcon = new L.Icon({
  iconUrl: marker_repair_icon,
  iconRetinaUrl: marker_repair_icon_svg,
  iconAnchor: [10, 35],
  shadowUrl: marker_iconShadow,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: [26, 40],
});

const SelectedIcon = new L.Icon({
  iconUrl: selected_marker_icon,
  iconRetinaUrl: selected_marker_icon_svg,
  iconAnchor: [10, 35],
  shadowUrl: marker_iconShadow,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: [26, 40],
});


const pulsatingIcon = L.divIcon({
  className: 'pulsating-icon',
  iconSize: [20, 20],
  iconAnchor: [10, 10]
});

L.Marker.prototype.options.icon = DefaultIcon;


function CustomControl() {
  const [location, setLocation] = useState(null);
  const [isRetrievingLocation, setIsRetrievingLocation] = useState(false);

  const handleMapLocate = (e) => {
    map.locate();
    setIsRetrievingLocation(true);
    setTimeout(() => {
      setIsRetrievingLocation(false);
    }, 4000);
  };

  const map = useMapEvents({
    locationfound(e) {
      setLocation(e.latlng);
      setIsRetrievingLocation(false);
      //map.flyTo(e.latlng, 15);
    },
  });

  return (
    <div className="leaflet-bar">
      <a className="leaflet-control-zoom-in" href="#" onClick={handleMapLocate} id="locate_user_position">
        {isRetrievingLocation ? <CircularProgress size={16} color="inherit" /> : <NearMeIcon sx={{ marginTop: '4px' }} />}
      </a>
      {location && (
        <Marker position={location} icon={L.icon({ iconUrl: youarehere, iconSize: [70, 50], iconAnchor: [10, 35] })} />
        //<PulsatingMarker center={location} />
      )}
    </div>
  )
}

function PulsatingMarker({ center }) {
  const [radius, setRadius] = useState(0);
  const map = useMap();

  useEffect(() => {
    const interval = setInterval(() => {
      setRadius((radius) => {
        const newRadius = (radius + 2) % 50;
        return newRadius === 0 ? 30 : newRadius;
      });
    }, 200);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const innerCircle = L.circleMarker(center, {
      radius: 5,
      fillColor: defaultTheme.palette.fifth.main,
      color: defaultTheme.palette.fifth.main,
      weight: 1,
      opacity: 0.5,
      fillOpacity: 0.8,
    }).addTo(map);

    const outerCircle = L.circleMarker(center, {
      radius: radius,
      fillColor: defaultTheme.palette.fifth.main,
      color: defaultTheme.palette.fifth.main,
      weight: 1,
      opacity: 0.5,
      fillOpacity: 0.2,
    }).addTo(map);

    return () => {
      map.removeLayer(innerCircle);
      map.removeLayer(outerCircle);
    };
  }, [center, map, radius]);

  return null;
}

const POSITION_CLASSES = {
  bottomleft: 'leaflet-bottom leaflet-left',
  bottomright: 'leaflet-bottom leaflet-right',
  topleft: 'leaflet-top leaflet-left',
  topright: 'leaflet-top leaflet-right',
}

const BOUNDS_STYLE = { weight: 1 }

function MinimapBounds({ parentMap, zoom }) {
  const minimap = useMap()

  // Clicking a point on the minimap sets the parent's map center
  const onClick = useCallback(
    (e) => {
      parentMap.setView(e.latlng, parentMap.getZoom())
    },
    [parentMap],
  )
  useMapEvent('click', onClick)

  // Keep track of bounds in state to trigger renders
  const [bounds, setBounds] = useState(parentMap.getBounds())
  const onChange = useCallback(() => {
    setBounds(parentMap.getBounds())
    // Update the minimap's view to match the parent map's center and zoom
    minimap.setView(parentMap.getCenter(), zoom)
  }, [minimap, parentMap, zoom])

  // Listen to events on the parent map
  const handlers = useMemo(() => ({ move: onChange, zoom: onChange }), [])
  useEventHandlers({ instance: parentMap }, handlers)
}

function MinimapControl({ position, zoom }) {
  const parentMap = useMap()
  const mapZoom = zoom || 6

  // Memoize the minimap so it's not affected by position changes
  const minimap = useMemo(
    () => (
      <div className="minimapContainer-wrapper">
        <MapContainer
          style={{ height: 180, width: 230 }}
          center={parentMap.getCenter()}
          zoom={mapZoom}
          dragging={false}
          doubleClickZoom={false}
          scrollWheelZoom={false}
          attributionControl={false}
          zoomControl={false}
        >
          <TileLayer
            attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy; <a href="https://carto.com/attributions">CARTO</a>'
            url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
          />
          <GeoJSON data={saxonyRegionsBorder} style={{ weight: 0.1, color: 'red', fillOpacity: 0.3 }}>
            <Tooltip sticky id="not_remap">
              <span style={{ fontSize: "9px", fontWeight: "bold" }}>Diese Regionen müssen noch auf unsere Karte!</span>
            </Tooltip>
          </GeoJSON>
          <GeoJSON data={saxonyRegionsBorderincluded} style={{ weight: 0.1, color: 'green', fillOpacity: 0.3 }}>
            <Tooltip sticky id="remap">
              <span style={{ fontSize: "9px", fontWeight: "bold" }}>Hier wird schon geReMapt!</span>
            </Tooltip>
          </GeoJSON>
          <MinimapBounds parentMap={parentMap} zoom={mapZoom} />
        </MapContainer>
      </div>
    ),
    [],
  )

  const positionClass =
    (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright
  return (
    <div className={positionClass}>
      <div className="leaflet-control leaflet-bar">{minimap}</div>
    </div>
  )
}

export const flyToLocation = (mapRef, latitude, longitude, zoom) => {
  if (mapRef.current) {
    mapRef.current.flyTo([latitude, longitude], zoom, {
      duration: 2,
    });
  }
};

function resizeMap(mapRef) {
  const resizeObserver = new ResizeObserver(() => mapRef.current?.invalidateSize());
  const container = document.getElementById('map-container');
  if (container) {
    resizeObserver.observe(container);
  }
}


const Map = forwardRef(({ data, isLoading, handleClick }, ref) => {
  const { fetchData } = useContext(AppContext);
  const mapRef = useRef(null);
  const forwardMapRef = useRef(null);
  const geoJsonRef = useRef(null);
  const geoJsonRef2 = useRef(null);
  const isDesktop = useMediaQuery(defaultTheme.breakpoints.up('sm'));
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const handleMarkerClickInternal = (item) => {
    setSelectedIndex(item.id);
    //flyToLocation(mapRef, parseFloat(item.locations[0].lat), parseFloat(item.locations[0].long), 13);
    handleClick(item);
  };

  const deleteSelectedMarkerInternal = () => {
    setSelectedIndex(-1);
  };

  function getMarkerIcon(index, item) {
    if (index === selectedIndex) {
      return SelectedIcon;
    } else if (item.categories && item.categories.map((category) => category.title).includes("Reparaturbonus")) {
      return RepairIcon;
    } else {
      return DefaultIcon;
    }
  }

  useEffect(() => {
    if (data && Object.keys(data).length > 0 && mapRef.current) {
      const bounds = L.latLngBounds();
    
      Object.keys(data).forEach(categoryName => {
        if (data[categoryName].visible) {
          data[categoryName].data.forEach(item => {
            if (item.locations.length > 0) {
              const { lat, long } = item.locations[0];
              if (lat && long) {
                bounds.extend([parseFloat(lat), parseFloat(long)]);
              }
            }
          });
        }
      });
    
      if (bounds.isValid()) {
        console.log("Fitting bounds");
        mapRef.current.fitBounds(bounds, { padding: [50, 50] });
        // Use flyToBounds if you prefer a smooth transition:
        // mapRef.current.flyToBounds(bounds, { padding: [50, 50] });
      } else {
        console.warn("No valid bounds to fit");
      }
    }


    const map = mapRef.current;
    const geoJsonLayer = geoJsonRef.current;
    const geoJsonLayer2 = geoJsonRef2.current;

    mapRef.current?.invalidateSize();

    const onZoomEnd = () => {
      const currentZoomLevel = map.getZoom();
      if (geoJsonLayer) {
        if (currentZoomLevel <= 10) {
          geoJsonLayer.eachLayer((layer) => {
            layer.setStyle({ opacity: 1, fillOpacity: 0.2 });
          });
        } else {
          geoJsonLayer.eachLayer((layer) => {
            layer.setStyle({ opacity: 0, fillOpacity: 0 });
          });
        }
      }
      if (geoJsonLayer2) {
        if (currentZoomLevel <= 10) {
          geoJsonLayer2.eachLayer((layer) => {
            layer.setStyle({ opacity: 1, fillOpacity: 0.2 });
          });
        } else {
          geoJsonLayer2.eachLayer((layer) => {
            layer.setStyle({ opacity: 0, fillOpacity: 0 });
          });
        }
      }
    };

    if (map && geoJsonLayer && geoJsonLayer2) {
      map.on('zoomend', onZoomEnd);
      onZoomEnd(); // Set the initial state
      return () => {
        map.off('zoomend', onZoomEnd);
      };
    }

    window.addEventListener('error', e => {
      if (e.message === 'ResizeObserver loop limit exceeded' || e.message === 'ResizeObserver loop completed with undelivered notifications.') {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });

  }, [data]);

  useImperativeHandle(ref, () => ({
    handleMarkerClick: handleMarkerClickInternal,
    deleteSelectedMarker: deleteSelectedMarkerInternal,
  }));


  return (
    <div className="mapContainer-wrapper">
      <MapContainer ref={mapRef} center={[51.340199, 12.360103]} zoom={13} style={{ height: isDesktop ? '100vh' : '83vh', width: "100%" }} zoomControl={false} whenReady={() => resizeMap(mapRef)} id="map-container">
        <TileLayer
          attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy; <a href="https://carto.com/attributions">CARTO</a>'
          url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
        />
        <Control prepend position='bottomright'>
          <Stack direction='column' spacing={1} >
            <CustomControl id="locate_btn" />
          </Stack>
        </Control>
        {isDesktop &&
          <ZoomControl position="bottomright" />
        }
        <GeoJSON data={saxonyBorder} style={{ weight: 1, color: 'black', fillOpacity: 0 }} />
        <GeoJSON data={saxonyRegionsBorder} style={{ weight: 0.3, fillOpacity: 0 }}>
        </GeoJSON>

        <div>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <MarkerClusterGroup chunkedLoading maxClusterRadius={14}>
              {Object.keys(data).map((categoryName) => {
                if (data[categoryName]["visible"] === true) {
                  return data[categoryName]["data"].map((item, itemIndex) => {
                    if (item.locations.length > 0 && item.locations[0].lat && item.locations[0].long) {
                      return (
                        <Marker
                          key={item.id}
                          index={item.id}
                          position={[parseFloat(item.locations[0].lat), parseFloat(item.locations[0].long)]}
                          eventHandlers={{
                            mouseover: (event) => event.target.openPopup(),
                            mouseout: (event) => event.target.closePopup(),
                            click: (event) => {
                              console.log(event.target.options.index);
                              setSelectedIndex(event.target.options.index)
                              handleMarkerClickInternal({ ...item, ...event, origin: 'map', click: () => handleMarkerClickInternal(item, event) });
                            }
                          }}
                          icon={getMarkerIcon(item.id, item)}
                        >
                          {isDesktop &&
                            <Tooltip offset={[15, -20]} className="map-popup" direction='right'>
                              <ListItem sx={{ mt: -1, mb: -1, p: 0 }}>
                                <ListItemAvatar>
                                  <Avatar>
                                    {item.image_url === null ? (
                                      <ShareLocationIcon style={{ color: 'black' }} />
                                    ) : (
                                      <img src={item.image_url} width="120px" alt={item.title} />
                                    )}
                                  </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                  primary={item.title}
                                  secondary={item.locations[0].street + ', ' + item.locations[0].zip + ' ' + item.locations[0].city}
                                />
                              </ListItem>
                            </Tooltip>
                          }
                        </Marker>
                      );
                    } else {
                      return null;
                    }
                  })
                }
              })}
            </MarkerClusterGroup>
          )}
        </div>
        {isDesktop &&
          <MinimapControl position="bottomleft" />
        }
      </MapContainer>
    </div >
  );
});

export default Map;
