import React, { useState, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Box, Card, CardMedia, CardContent, CardActions, Button, Typography, Grid } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import theme from '../../style/theme';
import 'swiper/css';
import './mobileliststyle.css';
import ShowMoreText from "react-show-more-text";

const MobileListComponent = ({ data, handleClick, location }) => {
  const listRef = useRef(null);

  const handleItemClick = (item, scrollIntoView = false) => {
    handleClick(item);
    if (scrollIntoView && listRef.current) {
      const listItem = listRef.current.querySelector(`[data-item-id="${item.id}"]`);
      if (listItem) {
        listItem.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  };

  return (
    <Box sx={{ width: "100vw", height: "26vh"}}>
      <Swiper
        centeredSlides={true}
        centeredSlidesBounds={true}
        slidesPerView={1.4}
        spaceBetween={5}
        pagination={{
          clickable: true,
        }}
      >
        {data.map((item, index) => {
          let borderColor;
          switch (item.offer_type.display_name) {
            case 'Projekt':
              borderColor = 'green';
              break;
            case 'Veranstaltung':
              borderColor = 'yellow';
              break;
            case 'Initiative':
              borderColor = 'red';
              break;
            default:
              borderColor = 'transparent';
              break;
          }
          return (
            <SwiperSlide key={uuidv4()} sx={{ height: "auto", alignSelf: "stretch"}}>
              <Card sx={{ ':hover': { bgcolor: "#ededed" }, borderRight: `2px solid ${borderColor}`, borderRadius: "2px", height: "100%" }}>
              <Grid direction="row" container>
                <Grid item xs={3}>
              <CardMedia
                  component="img"
                  alt="green iguana"
                  width="40"
                  image={item.image_url}
                />
              </Grid>
              <Grid item xs>
              <CardContent sx={{ overflowY: "auto", height: 70 }}>
                  <Typography gutterBottom variant="h6" sx={{ fontSize: 15, lineHeight: 1.1, overflowWrap: "anywhere" }}>
                  {item.title}
                  </Typography>
                  <ShowMoreText
                    /* Default options */
                    lines={3}
                    more="Mehr anzeigen"
                    less="Weniger anzeigen"
                    expanded={false}
                    truncatedEndingComponent={"... "}
                    className="show-more-text-mobile"
                >
                  <Typography variant="body2">
                  {item.short_description}
                  </Typography>
                  </ShowMoreText>
                </CardContent>
                <CardActions>
                <Button size="small" onClick={() => handleItemClick(item)}>Mehr Infos</Button>
                  <Button size="small">Favorit</Button>
                </CardActions>
                </Grid>
                </Grid>
              </Card>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Box>
  );
};

export default MobileListComponent;