import React, { useState } from 'react';
import { Stack, Chip } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Translate } from '@mui/icons-material';


const ResultsFilter = ({ selectedCategories, onCategoryToggle }) => {

    const toggleCategory = (category) => {
        onCategoryToggle(category);
    };

    return (
        <Stack direction="row" spacing={1} justifyContent={"center"} sx={{ position: "absolute", zIndex: 900, left: "10vw", top: "10px" }}>
            <Chip
                icon={selectedCategories.products ? <CheckBoxIcon fontSize="small" /> : <CheckBoxOutlineBlankIcon fontSize="small" />}
                label="Produkte & Dinge"
                clickable
                variant={selectedCategories.products ? "standard" : "outlined"}
                //change color conditionally based on selectedCategories
                color="primary"
                onClick={() => toggleCategory('products')}
            />
            <Chip
                icon={selectedCategories.education ? <CheckBoxIcon fontSize="small" /> : <CheckBoxOutlineBlankIcon fontSize="small" />}
                label="Bildung/Beratung"
                clickable
                variant={selectedCategories.education ? "standard" : "outlined"}
                color="primary"
                onClick={() => {
                    toggleCategory('education');
                    toggleCategory('counseling');
                }}
            />
            <Chip
                icon={selectedCategories.projects ? <CheckBoxIcon fontSize="small" /> : <CheckBoxOutlineBlankIcon fontSize="small" />}
                label="Projekte"
                clickable
                variant={selectedCategories.projects ? "standard" : "outlined"}
                color="primary"
                onClick={() => toggleCategory('projects')}
            />
            <Chip
                icon={selectedCategories.events ? <CheckBoxIcon fontSize="small" /> : <CheckBoxOutlineBlankIcon fontSize="small" />}
                label="Veranstaltungen"
                clickable
                variant={selectedCategories.events ? "standard" : "outlined"}
                color="primary"
                onClick={() => toggleCategory('events')}
            />

        </Stack>
    );
};

export default ResultsFilter;